import * as React from "react";
import { navigate } from 'gatsby';

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from 'dayjs';
import yn from "yn";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import Feedback from './Feedback';
import OrderSteps from './OrderSteps';

import { useIOProducts } from "../../hooks/use-io-products";

import { trackGtagEvent } from "../../utils"

const useStyles = makeStyles((theme) => ({
    buttonSticky: {
        position: "sticky",
        bottom: 10,
        right: 10,
        width: "100vw"
    },
    buttonRoot: {
        margin: "0 auto",
        [theme.breakpoints.up('md')]: {
            width: "70%"
        }
    },
    stepperRoot: {
        background: '#f5f5f5'
    },
    stepWidth: {
        margin: '0 auto',
        width: 320,
        minHeight: 200,
        [theme.breakpoints.up("sm")]: {
            width: 400,
        },
        [theme.breakpoints.up("md")]: {
            width: 600,
        },
        [theme.breakpoints.up("big")]: {
            width: 650,
        },
        [theme.breakpoints.up("lg")]: {
            width: 800,
        },
    }
}));

const MAINTENANCE_MODE = yn(process.env.GATSBY_MAINTENANCE_MODE);
const MOD_API_URL = process.env.GATSBY_MOD_API_URL;

export default function CreateOrder() {
  const { IO_PRODUCT_LOOKUP } = useIOProducts();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);
  const steps = ["What", "Where", "When"];
  const classes = useStyles();

  const validationSchema = [
    //validation for step1 (what)
    yup.object({
      product: yup.string().required()
    }),
    //validation for step2 (where)
    yup.object({
      place: yup.object().shape({
        label: yup.string().required(),
        geojson_url: yup.string().optional(),
        geojson_obj: yup.object().optional(),
        slug: yup.string().required(),
        area_km2: yup.number().required().test(
            'is-within-aoi-limits',
            'Please pick an area of interest within limits',
            (value, ctx) => {
                // pull product value from context, use that to look up max
                let product = ctx.from[ctx.from.length - 1].value.product;
                let max = IO_PRODUCT_LOOKUP[product]?.aoi_maxsize;
                return (value < max)
            }
        ),
        centroid: yup.array().length(2).required(),
      })
    }),
    //validation for step3 (when)
    yup.object({
      date_range: yup.object().shape({
        start_date: yup.date()
          .typeError('Start date must be a valid date')
          .min("2017-01-01", "Start date must be after Jan 1, 2017"),
        end_date: yup.date()
          .typeError('End date must be a valid date')
          .min("2017-05-01", "End date must be after May 1, 2017")
          .max(dayjs().format('YYYY-MM-DD'), "End date must be before today")
      })
    })
  ];
  const stepValidationSchema = validationSchema[activeStep];

  const formMethods = useForm({
    mode: "onBlur",
    resolver: yupResolver(stepValidationSchema),
    defaultValues: {
      "product": "",
      "date_range": {
        "start_date": dayjs().subtract(120, "days").format('YYYY-MM-DD'),
        "end_date": dayjs().format('YYYY-MM-DD'),
      },
      "date_suggestion": {
        "start_date": "",
        "end_date": "",
      },
      "place": {
        "label": "",
        "geojson_url": "",
        "slug": "",
        "area_km2": "",
        "centroid": "",
      }
    }
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const POST_DATA = {
        "start_date": dayjs(data.date_range.start_date).format('YYYY-MM-DD'),
        "end_date": dayjs(data.date_range.end_date).format('YYYY-MM-DD'),
        "aoi_name": data.place.slug,
        "aoi_label": data.place.label,
        "product_id": data.product,
    }
    if (data.place.geojson_url) {
      POST_DATA["aoi_geojson_path"] = data.place.geojson_url
      // note rename from geojson_url to geojson_path, even though it's actually a URL
    } else if (data.place.geojson_obj) {
      POST_DATA["aoi_geojson_obj"] = data.place.geojson_obj
    }

    fetch(`${MOD_API_URL}/order`,
      {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(POST_DATA)
      })
      .then(async(response) => {
        if(response.ok) {
          return await response.json()
        } else if (response.status >= 500) {
          const body = await response.json();
          console.error(body["detail"]);
          throw new Error("Server error. The webmaster has been notified.")
        } else if (response.status >= 400) {
            // MOD errors are in detail with message as key and more info as value
            const body = await response.json();
            console.error(body["detail"]);
            throw new Error('Insufficient satellite imagery due to clouds or missing data. Select a different date range or location reach out to hello@impactobservatory.com.')
        }
      })
      .then(function(data){
        trackGtagEvent('add_to_cart', {currency: "USD", value: data.cost/100, items: {
          item_id: data.product_id,
          item_name: IO_PRODUCT_LOOKUP[data.product_id].name
        }})
        setIsSubmitting(false);
        if (data && data.id) {
          navigate(`/order/${data.id}/`, {state: {order: data, disableScrollUpdate: true}})
          // include the data in the redirect, so we don't need to reload
          // maintain scroll position by setting disableScrollUpdate
        } else {
          console.error('unable to place order', data);
          throw new Error("Unable to place order")
        }
      })
      .catch(e => {
        console.error(e);
        setSubmitError(e);
        setIsSubmitting(false);
        return false;
      })
  }

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      // show spinner before submitting
      const errors = formMethods.formState.errors;
      if(Object.keys(errors).length === 0) {
        setIsSubmitting(true);
      } else {
        console.error(errors)
      }
      return true;
    } else {
      // validate each step as we go
      const isStepValid = await formMethods.trigger();
      if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    formMethods.reset();
    setActiveStep(0);
  };

  const selectedProduct = formMethods.watch("product");

  React.useEffect( redirectToMaintenance => {
    if(MAINTENANCE_MODE) {
      navigate(`/maintenance/`);
    }
  }, [])

  return (<div className="createOrder">
    <Container>
      <Grid container spacing={3} justifyContent="center">
        <Grid item md={9}>
          <Typography variant="h4" component="h2" textAlign="center">
            Create Your Order
          </Typography>
          <Typography variant="body1" textAlign="left" sx={{mt:2}}>
            Our Land Use and Land Cover maps identify patterns and changes anywhere on land.
            To order a map, select the product that meets your needs, find your area of interest, and specify a date range.
            Maps will be delivered to your email for download within a few hours.
          </Typography>
          <Typography variant="body1" textAlign="left" sx={{mt:2}}>
            We are always working on new products at IO. <a href="https://www.impactobservatory.com/" target="_blank">Learn more</a> and inquire about our beta offerings.
          </Typography>
        </Grid>
      </Grid>
    </Container>

    <form className={classes.stepperRoot}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => {
                return (
                  <Step key={`step-${index}`}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>

          <Grid item container xs={12}>
            <Box className={classes.stepWidth}>
              <Box>
                { isSubmitting ? (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                ) : (
                  <OrderSteps step={activeStep} formMethods={formMethods} submitError={submitError} handleNext={handleNext} />
                )}
              </Box>
            </Box>
          </Grid>

          { !isSubmitting && (
          <Grid item xs={12} className={activeStep === 0 ? classes.buttonSticky : ''}>
            <Box className={classes.buttonRoot}>
              <Grid container justifyContent={"space-between"}>
                <Box>
                  {activeStep > 0 && (
                    <Button
                      size="small"
                      variant="unstyled"
                      onClick={handleReset}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
                <Box>
                  {activeStep > 0 && (
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={isSubmitting}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || (activeStep === 0 && !selectedProduct)}
                    onClick={activeStep === steps.length - 1 ? formMethods.handleSubmit(onSubmit): handleNext}
                  >
                    Next
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
          )}
        </Grid>
      </Container>
    </form>

    <Feedback className={classes.stepWidth} dark={true} />
  </div>);
}
